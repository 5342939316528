import { useToast } from 'primevue/usetoast'

export function useErrorToast() {
    const toast = useToast()
    function showErrorToast(header: string = '', message: string = '') {
        toast.add({ 
            severity: 'error', 
            styleClass: 'error-toast',
            summary: header, 
            detail: message, 
            life: 5000 
          })
    }

    return showErrorToast;
}